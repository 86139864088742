import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "users-table", fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { icon: "mdi-account", inline: "", color: "secondary" },
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function () {
                return [
                  _c(VIcon, { attrs: { large: "" } }, [
                    _vm._v("mdi-account"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h5" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$tc("user", 2)))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                rounded: "",
                absolute: "",
                fab: "",
                top: "",
                right: "",
                small: "",
              },
              on: {
                click: function ($event) {
                  return _vm.add_user(null)
                },
              },
            },
            [_c(VIcon, [_vm._v("mdi-plus")])],
            1
          ),
          _c(
            VTabs,
            {
              attrs: { "show-arrows": "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                VTab,
                {
                  attrs: {
                    id: _vm.TableEnum.INVESTOR,
                    value: _vm.TableEnum.INVESTOR,
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("investor", 2)))]
              ),
              _c(
                VTab,
                {
                  attrs: {
                    id: _vm.TableEnum.ADMIN,
                    value: _vm.TableEnum.ADMIN,
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("admin", 2)))]
              ),
              _c(
                VTab,
                {
                  attrs: {
                    id: _vm.TableEnum.PARTNER,
                    value: _vm.TableEnum.PARTNER,
                    "data-test": "Admin:UsersTable:BtnPartner",
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("partner", 2)))]
              ),
              _c(
                VTab,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: 1 == 0,
                      expression: "1 == 0",
                    },
                  ],
                  attrs: {
                    id: _vm.TableEnum.TRANSLATOR,
                    value: _vm.TableEnum.TRANSLATOR,
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("translator", 2)))]
              ),
              _c(
                VTab,
                {
                  attrs: {
                    id: _vm.TableEnum.FINANCIALANALYSTS,
                    value: _vm.TableEnum.FINANCIALANALYSTS,
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("financial_analyst", 2)))]
              ),
              _c(
                VTab,
                {
                  attrs: {
                    id: _vm.TableEnum.CONTENTANALYSTS,
                    value: _vm.TableEnum.CONTENTANALYSTS,
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("content_analyst", 2)))]
              ),
              _c(
                VTab,
                {
                  attrs: {
                    id: _vm.TableEnum.BANKERS,
                    value: _vm.TableEnum.BANKERS,
                    "data-test": "Admin:UsersTable:BtnBanker",
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("banker", 2)))]
              ),
              _c(
                VTab,
                {
                  attrs: {
                    id: _vm.TableEnum.ALPHAANALYSTS,
                    value: _vm.TableEnum.BANKERS,
                    "data-test": "Admin:UsersTable:BtnAlphaAnalyst",
                  },
                },
                [_vm._v(_vm._s(_vm.$tc("alpha_analyst", 2)))]
              ),
              _vm.envManagerUser === "TRUE"
                ? _c(
                    VTab,
                    {
                      attrs: {
                        id: _vm.TableEnum.MANAGER_USER,
                        value: _vm.TableEnum.MANAGER_USER,
                        "data-test": "Admin:UsersTable:BtnManager",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("managers")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("InvestorTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab == _vm.TableEnum.INVESTOR,
                expression: "tab == TableEnum.INVESTOR",
              },
            ],
            attrs: { UserFullName: _vm.user_full_name },
          }),
          _c("AdminTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab == _vm.TableEnum.ADMIN,
                expression: "tab == TableEnum.ADMIN",
              },
            ],
          }),
          _c("PartnerTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab == _vm.TableEnum.PARTNER,
                expression: "tab == TableEnum.PARTNER",
              },
            ],
          }),
          _c("FinancialAnalystsTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab == _vm.TableEnum.FINANCIALANALYSTS,
                expression: "tab == TableEnum.FINANCIALANALYSTS",
              },
            ],
            attrs: { UserType: _vm.TableEnum.FINANCIALANALYSTS },
          }),
          _c("ContentAnalystsTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab == _vm.TableEnum.CONTENTANALYSTS,
                expression: "tab == TableEnum.CONTENTANALYSTS",
              },
            ],
            attrs: { UserType: _vm.TableEnum.CONTENTANALYSTS },
          }),
          _c("BankersTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab == _vm.TableEnum.BANKERS,
                expression: "tab == TableEnum.BANKERS",
              },
            ],
          }),
          _c("AlphaAnalystsTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab == _vm.TableEnum.ALPHAANALYSTS,
                expression: "tab == TableEnum.ALPHAANALYSTS",
              },
            ],
            attrs: { UserType: _vm.TableEnum.ALPHAANALYSTS },
          }),
          _c("ManagerUserTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.tab == _vm.TableEnum.MANAGER_USER &&
                  _vm.envManagerUser === "TRUE",
                expression:
                  "tab == TableEnum.MANAGER_USER && envManagerUser === 'TRUE'",
              },
            ],
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }